body{
    font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.bg-secondary{
    background-color: rgb(44, 62, 80) !important;
}

.main-banner{
    background-color: rgb(26, 188, 156);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 96px;
    padding-top: 100px;
    text-align: center;
}

.main-logo{
    width: 350px;
    max-width: 50%;
}

.navbar-brand{
    font-size: 1.5rem;
}

h2{
    margin-top: 2rem;
    font-size:3rem;
    color: rgb(44, 62, 80);

}

p{
    font-size: 1.5rem;
}

.nav-link{
    font-size: 1.5rem;
}

#mainNav {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
}

.green-bg{
    background-color: rgb(26, 188, 156);
}
